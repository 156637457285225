<template>
    <div>
        <div v-if="category">{{ category.name }}</div>
        <div v-else>Brak</div>
    </div>
</template>

<script>
export default {
    props: {
        categoryId: {
            type: String,
        },
    },
    watch: {
        categoryId: {
            immediate: true,
            async handler(newCategoryId) {
                if (newCategoryId) {
                    try {
                        this.category = await this.$store.dispatch("fetchedElements/getElement", {
                            type: "spe_categories",
                            id: newCategoryId
                        });
                    } catch (error) {
                        console.error(error);
                    }
                } else {
                    this.category = null;
                }
            }
        }
    },
    data() {
        return {
            category: null
        };
    },
};
</script>
