<template>
    <div class="cbe">
        <div
            class="cbe__wrapper"
            :class="{
                'cbe__wrapper--wide': wide_cbe_layout
            }"
        >
            <v-sheet
                outlined
                rounded="lg"
                style="overflow: hidden"
            >
                <div
                    class="py-10 px-4 grey lighten-4 text-center"
                    v-if="ld.length == 0"
                >
                    <template v-if="!disabled">
                        <div class="mb-1">
                            Rozpocznij tworzenie treści -&nbsp;kliknij przycisk poniżej i&nbsp;dodaj
                            swój pierwszy blok:
                        </div>
                        <v-btn
                            text
                            color="success"
                            @click.stop="
                                create_dialog_index = 0;
                                create_dialog = true;
                            "
                        >
                            <v-icon
                                size="20"
                                class="mr-1"
                                >mdi-plus</v-icon
                            >
                            Dodaj blok
                        </v-btn>
                    </template>
                    <template v-else>
                        <div class="mb-1 text-caption text-uppercase grey--text">
                            Edycja wyłączona
                        </div>
                        Nie dodano żadnej treści
                    </template>
                </div>

                <Container
                    drag-class="cbe-block--dragging"
                    drag-area-selector=".cbe-block__dragoverlay"
                    :non-drag-area-selector="blocksFilter !== 0 ? '.cbe-block' : undefined"
                    :drop-placeholder="{
                        className: 'cbe-block__dropph'
                    }"
                    @drop="onCbReorder"
                    v-if="ld.length > 0"
                >
                    <Draggable
                        v-for="(block, ix) in ld"
                        :key="block.oid"
                    >
                        <ContentBlock
                            :block="block"
                            :is-first="ix === 0"
                            :is-last="ix === ld.length - 1"
                            @create-block="
                                position => {
                                    create_dialog_index = position == 'before' ? ix : ix + 1;
                                    create_dialog = true;
                                }
                            "
                            @edit-block="openEditDialog(block)"
                            @delete-block="
                                () => {
                                    delete_dialog_block = block;
                                    delete_dialog = true;
                                }
                            "
                            @move-block="dir => onBlockMoveRequest(ix, dir)"
                            @contextmenu.native="ev => showContextMenu(ev, ix)"
                            @dblclick.native="openEditDialog(block)"
                            @update="emitUpdate"
                            :drag-disabled="disabled || reorderDisabled"
                            :show-visibility-icons="blocksFilter === 0"
                            :style="canShowBlock(block) ? {} : { display: 'none' }"
                            :quiz-boards="quizBoards"
                            :blocks-filter="blocksFilter"
                        />
                    </Draggable>
                </Container>

                <!-- CONTEXT MENU (OGÓLNE) -->
                <v-menu
                    v-model="ctx_menu"
                    :position-x="ctx_menu_x"
                    :position-y="ctx_menu_y"
                    absolute
                    offset-y
                    transition="slide-y-transition"
                >
                    <div
                        class="white wkmm-ctxmenu"
                        @contextmenu.prevent
                    >
                        <v-btn
                            text
                            color="grey darken-2"
                            large
                            style="text-transform: none"
                            class="font-weight-regular text-body-2"
                            @click="
                                () => {
                                    create_dialog_index = ctx_menu_ix;
                                    create_dialog = true;
                                }
                            "
                        >
                            <v-icon
                                class="mr-2"
                                color="grey darken-1"
                                >mdi-table-row-plus-before</v-icon
                            >
                            Wstaw blok przed tym blokiem
                        </v-btn>
                        <v-btn
                            text
                            color="grey darken-2"
                            large
                            style="text-transform: none"
                            class="font-weight-regular text-body-2"
                            @click="onBlockMoveRequest(ctx_menu_ix, -1)"
                            :disabled="ctx_menu_ix === 0 || reorderDisabled"
                        >
                            <v-icon
                                class="mr-2"
                                color="grey darken-1"
                                >mdi-arrow-up</v-icon
                            >
                            Przenieś blok o jedną pozycję w górę
                        </v-btn>

                        <v-btn
                            text
                            color="grey darken-2"
                            large
                            style="text-transform: none"
                            class="font-weight-regular text-body-2"
                            @click="openEditDialog(ld[ctx_menu_ix])"
                            :disabled="
                                ld[ctx_menu_ix] &&
                                ld[ctx_menu_ix].block_type ===
                                    ContentBlockType.QUIZ_BOARD_PREDEFINED_CONTENT
                            "
                        >
                            <v-icon
                                class="mr-2"
                                color="grey darken-1"
                                >mdi-pencil</v-icon
                            >
                            Edytuj blok
                        </v-btn>

                        <v-btn
                            text
                            color="error"
                            large
                            style="text-transform: none"
                            class="font-weight-regular text-body-2"
                            @click="
                                () => {
                                    delete_dialog_block = JSON.parse(
                                        JSON.stringify(ld[ctx_menu_ix])
                                    );
                                    delete_dialog = true;
                                }
                            "
                        >
                            <v-icon
                                class="mr-2"
                                color="error"
                                >mdi-delete</v-icon
                            >
                            Usuń blok
                        </v-btn>

                        <v-btn
                            text
                            color="grey darken-2"
                            large
                            style="text-transform: none"
                            class="font-weight-regular text-body-2"
                            @click="onBlockMoveRequest(ctx_menu_ix, 1)"
                            :disabled="ctx_menu_ix === ld.length - 1 || reorderDisabled"
                        >
                            <v-icon
                                class="mr-2"
                                color="grey darken-1"
                                >mdi-arrow-down</v-icon
                            >
                            Przenieś blok o jedną pozycję w dół
                        </v-btn>
                        <v-btn
                            text
                            color="grey darken-2"
                            large
                            style="text-transform: none"
                            class="font-weight-regular text-body-2"
                            @click="
                                () => {
                                    create_dialog_index = ctx_menu_ix + 1;
                                    create_dialog = true;
                                }
                            "
                        >
                            <v-icon
                                class="mr-2"
                                color="grey darken-1"
                                >mdi-table-row-plus-after</v-icon
                            >
                            Wstaw blok po tym bloku
                        </v-btn>
                    </div>
                </v-menu>
            </v-sheet>
        </div>

        <!-- ################## -->
        <!-- ##### DIALOGS #### -->
        <!-- ################## -->
        <!-- CREATE DIALOG -->
        <v-dialog
            v-model="create_dialog"
            max-width="400"
            scrollable
        >
            <v-card>
                <v-card-title> Tworzenie bloku treści </v-card-title>
                <v-divider></v-divider>
                <v-card-text
                    class="pt-5"
                    style="max-height: 70vh"
                >
                    <v-select
                        outlined
                        v-model="create_dialog_block_type"
                        label="Typ bloku"
                        :items="create_dialog_block_type_select_items"
                        hide-details
                    >
                        <template v-slot:item="{ item }">
                            <div>
                                {{ item.text }}
                                <div
                                    class="text-caption grey--text mt-n1"
                                    v-if="item.description"
                                >
                                    {{ item.description }}
                                </div>
                            </div>
                        </template>
                    </v-select>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="grey lighten-1"
                        :disabled="create_dialog_loading"
                        @click="create_dialog = false"
                    >
                        Anuluj
                    </v-btn>
                    <v-btn
                        text
                        color="success"
                        :loading="create_dialog_loading"
                        @click="createBlock"
                    >
                        Utwórz
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- EDIT DIALOG -->
        <v-dialog
            v-model="edit_dialog"
            :max-width="
                edit_dialog_block && edit_dialog_block.block_type == ContentBlockType.IMAGE
                    ? 500
                    : 920
            "
            scrollable
            persistent
        >
            <v-card v-if="edit_dialog_block">
                <v-card-title>
                    <div>
                        Edycja bloku treści
                        <div class="text-caption grey--text mt-n1">
                            ID: {{ edit_dialog_block.oid }}
                        </div>
                        <div class="text-caption grey--text mt-n1">
                            Typ: {{ ContentBlockTypeLabel[edit_dialog_block.block_type] }}
                        </div>
                    </div>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text
                    class="pt-5 black--text"
                    style="max-height: 75vh"
                >
                    <!-- GENERAL -->
                    <div class="d-flex align-center mb-3">
                        <v-divider class="flex-grow-1"></v-divider>
                        <div class="text-caption grey--text flex-shrink-0 px-2">
                            Ustawienia ogólne bloku
                        </div>
                        <v-divider class="flex-grow-1"></v-divider>
                    </div>
                    <div>
                        <div class="text-caption text-uppercase mb-2 grey--text">
                            Widoczność planszy
                        </div>
                        <v-row dense>
                            <v-col cols="12">
                                <v-switch
                                    class="mt-0"
                                    hide-details
                                    inset
                                    v-model="edit_dialog_block.visible_sm"
                                >
                                    <template v-slot:label>
                                        <v-icon
                                            size="20"
                                            class="ml-n2"
                                            >mdi-cellphone</v-icon
                                        >
                                        Mobile
                                    </template>
                                </v-switch>
                            </v-col>
                            <v-col cols="12">
                                <v-switch
                                    class="mt-0"
                                    hide-details
                                    inset
                                    v-model="edit_dialog_block.visible_md"
                                >
                                    <template v-slot:label>
                                        <v-icon
                                            size="20"
                                            class="ml-n1 mr-1"
                                            >mdi-tablet</v-icon
                                        >
                                        Tablet
                                    </template>
                                </v-switch>
                            </v-col>
                            <v-col cols="12">
                                <v-switch
                                    class="mt-0"
                                    hide-details
                                    inset
                                    v-model="edit_dialog_block.visible_lg"
                                >
                                    <template v-slot:label>
                                        <v-icon
                                            size="20"
                                            class="ml-n1 mr-1"
                                            >mdi-monitor</v-icon
                                        >
                                        PC
                                    </template>
                                </v-switch>
                            </v-col>
                        </v-row>
                    </div>

                    <!-- BLOCK SPECIFIC -->
                    <div
                        class="d-flex align-center mb-3 mt-8"
                        v-if="
                            ![
                                ContentBlockType.QUIZ_BOARD_PREDEFINED_CONTENT,
                                ContentBlockType.QUIZ_NEXT_BOARD_BUTTON_PLACEHOLDER
                            ].includes(edit_dialog_block.block_type)
                        "
                    >
                        <v-divider class="flex-grow-1"></v-divider>
                        <div class="text-caption grey--text flex-shrink-0 px-2">
                            Ustawienia bloku "{{
                                ContentBlockTypeLabel[edit_dialog_block.block_type]
                            }}"
                        </div>
                        <v-divider class="flex-grow-1"></v-divider>
                    </div>

                    <!-- IMAGE -->
                    <template
                        v-if="
                            edit_dialog_block.block_type == ContentBlockType.IMAGE &&
                            edit_dialog_image
                        "
                    >
                        <WkMediaManager
                            file-select
                            @file="
                                file => {
                                    if (file.display_type != MediamanagerFileDisplayType.IMAGE) {
                                        $message({
                                            type: 'error',
                                            msg: 'Proszę wybrać plik będący obrazem'
                                        });
                                    } else {
                                        edit_dialog_image = {
                                            ...edit_dialog_image,
                                            url: file.url,
                                            url_hq: file.url_hq,
                                            url_thumb: file.url_thumb,
                                            url_placeholder: file.url_placeholder,
                                            mimetype: file.mimetype
                                        };
                                        $refs.media_manager.close();
                                    }
                                }
                            "
                            ref="media_manager"
                        />

                        <v-text-field
                            outlined
                            readonly
                            :value="edit_dialog_image.url"
                            label="Wybrany plik"
                            @click.prevent="$refs.media_manager.open()"
                            hide-details
                            class="mb-4"
                        >
                            <template v-slot:append>
                                <v-icon>mdi-image-search</v-icon>
                            </template>
                        </v-text-field>

                        <v-text-field
                            outlined
                            label="Podpis obrazka (opcjonalny)"
                            v-model="edit_dialog_image.caption"
                            ref="edit_dialog_image_caption"
                            :rules="[
                                v => {
                                    if (!v || v.length === 0) return true;
                                    if (v.length > 127)
                                        return 'Maksymalna długość podpisu to 127 znaków';
                                    return true;
                                }
                            ]"
                            hide-details="auto"
                            class="mb-4"
                        />

                        <v-text-field
                            outlined
                            label="Atrybut alt (opcjonalny)"
                            v-model="edit_dialog_image.alt"
                            ref="edit_dialog_image_alt"
                            :rules="[
                                v => {
                                    if (!v || v.length === 0) return true;
                                    if (v.length > 127)
                                        return 'Maksymalna długość atrybutu alt to 127 znaków';
                                    return true;
                                }
                            ]"
                            hide-details="auto"
                        />
                    </template>
                    <!-- WYSIWYG -->
                    <template
                        v-else-if="
                            edit_dialog_block.block_type == ContentBlockType.WYSIWYG &&
                            edit_dialog_wysiwyg
                        "
                    >
                        <TipTapWysiwyg
                            v-model="edit_dialog_wysiwyg.content"
                            :extensions="wysiwyg_editor_extensions"
                            :quiz-boards="quizBoards"
                        />
                    </template>
                    <!-- ASL -->
                    <template
                        v-else-if="
                            edit_dialog_block.block_type ==
                                ContentBlockType.ANIMATED_SUMMARY_LIST && edit_dialog_asl
                        "
                    >
                        <h4 class="text-body-2 text-uppercase grey--text font-weight-regular mb-1">
                            Elementy do wyświetlenia
                        </h4>
                        <div
                            v-if="edit_dialog_asl.items.length === 0"
                            class="pa-2 grey lighten-4 rounded text-center text-caption grey--text"
                        >
                            Nie dodano jeszcze żadnego elementu
                        </div>
                        <Container
                            @drop="onAslItemDrop"
                            v-if="edit_dialog_asl.items.length > 0"
                        >
                            <Draggable
                                v-for="(it, index) in edit_dialog_asl.items"
                                :key="index"
                            >
                                <v-card
                                    class="px-4 py-2 mb-2 d-flex align-center"
                                    style="cursor: grab"
                                    outlined
                                >
                                    <p
                                        class="font-weight-medium primary--text ma-0 mr-4 flex-shrink-0"
                                    >
                                        {{ index + 1 }}.
                                    </p>
                                    <div class="mr-2">
                                        <v-icon
                                            size="24"
                                            color="primary"
                                            class="mr-1"
                                            >{{
                                                ContentBlockAnimatedSummaryListItemIconName[it.icon]
                                            }}</v-icon
                                        >
                                    </div>
                                    <div>
                                        <div
                                            v-for="(e, ix) in getPossibleLogicExpressionOutputs(
                                                it.text
                                            )"
                                            :key="ix"
                                            :class="{
                                                'mt-n1': ix > 0
                                            }"
                                        >
                                            <span
                                                v-if="ix > 0"
                                                class="grey--text"
                                            >
                                                lub
                                            </span>
                                            {{ e }}
                                        </div>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        icon
                                        small
                                        color="grey"
                                        class="ml-2 flex-shrink-0"
                                        @click="
                                            () => {
                                                asl_edit_dialog_item = JSON.parse(
                                                    JSON.stringify(it)
                                                );
                                                asl_edit_dialog_index = index;
                                                asl_edit_dialog = true;
                                            }
                                        "
                                    >
                                        <v-icon size="20"> mdi-pencil </v-icon>
                                    </v-btn>
                                    <v-btn
                                        icon
                                        small
                                        color="error"
                                        class="flex-shrink-0"
                                        @click="
                                            () => {
                                                asl_delete_dialog_index = index;
                                                asl_delete_dialog = true;
                                            }
                                        "
                                    >
                                        <v-icon size="20"> mdi-delete </v-icon>
                                    </v-btn>
                                </v-card>
                            </Draggable>
                        </Container>

                        <div class="mt-2 text-right">
                            <v-btn
                                v-if="edit_dialog_asl.items.length < 16"
                                text
                                color="primary"
                                small
                                @click="asl_create_dialog = true"
                            >
                                <v-icon size="16">mdi-plus</v-icon>
                                Dodaj element
                            </v-btn>
                            <div
                                v-else
                                class="text-caption grey--text"
                            >
                                Dodano maksymalną ilość elementów (16)
                            </div>
                        </div>

                        <!-- ASL ITEM CREATE DIALOG -->
                        <v-dialog
                            v-model="asl_create_dialog"
                            scrollable
                            max-width="680"
                            persistent
                        >
                            <v-card>
                                <v-card-title> Dodawanie elementu </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text
                                    style="max-height: 75vh"
                                    class="pt-5"
                                >
                                    <ContentBlockAnimatedSummaryListForm
                                        ref="asl_create_dialog_form"
                                        v-if="asl_create_dialog"
                                        :quiz-boards="quizBoards"
                                    />
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="grey"
                                        text
                                        @click="asl_create_dialog = false"
                                    >
                                        Anuluj
                                    </v-btn>
                                    <v-btn
                                        color="success"
                                        text
                                        @click="createAslItem"
                                    >
                                        Dodaj
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <!-- ASL ITEM EDIT DIALOG -->
                        <v-dialog
                            v-model="asl_edit_dialog"
                            scrollable
                            max-width="680"
                            persistent
                        >
                            <v-card>
                                <v-card-title> Edycja elementu </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text
                                    style="max-height: 75vh"
                                    class="pt-5"
                                >
                                    <ContentBlockAnimatedSummaryListForm
                                        ref="asl_edit_dialog_form"
                                        v-if="asl_edit_dialog"
                                        :quiz-boards="quizBoards"
                                        :value="asl_edit_dialog_item"
                                    />
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="grey"
                                        text
                                        @click="asl_edit_dialog = false"
                                    >
                                        Anuluj
                                    </v-btn>
                                    <v-btn
                                        color="success"
                                        text
                                        @click="editAslItem"
                                    >
                                        Zapisz
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <!-- ASL ITEM DELETE DIALOG -->
                        <v-dialog
                            v-model="asl_delete_dialog"
                            max-width="450"
                        >
                            <v-card v-if="asl_delete_dialog">
                                <v-card-title> Usuwanie elementu </v-card-title>
                                <v-card-text>
                                    Czy na pewno usunąć element
                                    <span class="font-weight-medium">{{
                                        getPossibleLogicExpressionOutputs(
                                            edit_dialog_asl.items[asl_delete_dialog_index].text
                                        ).join("/")
                                    }}</span
                                    >?
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="grey"
                                        text
                                        @click="asl_delete_dialog = false"
                                    >
                                        Anuluj
                                    </v-btn>
                                    <v-btn
                                        color="error"
                                        text
                                        @click="deleteAslItem"
                                    >
                                        Tak, usuń
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </template>
                    <!-- CHART -->
                    <template
                        v-else-if="
                            edit_dialog_block.block_type == ContentBlockType.CHART &&
                            edit_dialog_chart
                        "
                    >
                        <v-row dense>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-select
                                    outlined
                                    label="Typ wykresu"
                                    v-model="edit_dialog_chart.chart_type"
                                    @input="onChartTypeChangeInEditDialog"
                                    :items="ContentBlockChartTypeSelectItems"
                                    hide-details="auto"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    outlined
                                    label="Wysokość wykresu (px)"
                                    type="number"
                                    v-model="edit_dialog_chart.height"
                                    ref="edit_dialog_chart_height"
                                    hide-details="auto"
                                    :rules="[
                                        v => {
                                            if (!v || v.length == 0) return 'Pole wymagane';
                                            if (parseInt(v) < 200 || parseInt(v) > 1000)
                                                return 'Wysokość musi wynosić 200-1000 pikseli';
                                            return true;
                                        }
                                    ]"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    outlined
                                    label="Margines górny (px)"
                                    type="number"
                                    v-model="edit_dialog_chart.margin_top"
                                    ref="edit_dialog_chart_margin_top"
                                    hide-details="auto"
                                    :rules="[
                                        v => {
                                            if (v.length == 0) return 'To pole jest wymagane';
                                            if (isNaN(parseInt(v))) {
                                                return 'Proszę podać liczbę';
                                            }
                                            if (parseInt(v) < -255 || parseInt(v) > 255)
                                                return 'Margines można określić w zakresie od -255px do 255px';
                                            return true;
                                        }
                                    ]"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    outlined
                                    label="Margines dolny (px)"
                                    type="number"
                                    v-model="edit_dialog_chart.margin_bottom"
                                    ref="edit_dialog_chart_margin_bottom"
                                    hide-details="auto"
                                    :rules="[
                                        v => {
                                            if (v.length == 0) return 'To pole jest wymagane';
                                            if (isNaN(parseInt(v))) {
                                                return 'Proszę podać liczbę';
                                            }
                                            if (parseInt(v) < -255 || parseInt(v) > 255)
                                                return 'Margines można określić w zakresie od -255px do 255px';
                                            return true;
                                        }
                                    ]"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    outlined
                                    label="Format etykiety osi Y"
                                    v-model="edit_dialog_chart.yaxis_label_format"
                                    ref="edit_dialog_chart_yaxis_label_format"
                                    hide-details="auto"
                                    :rules="[
                                        v => {
                                            if (!v || v.length === 0) return true;
                                            if (v.length > 15)
                                                return 'Maksymalna długość pola to 15 znaków';
                                            return true;
                                        }
                                    ]"
                                >
                                    <template v-slot:append>
                                        <v-tooltip
                                            bottom
                                            max-width="350"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                    size="18"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="ml-2"
                                                >
                                                    mdi-help-circle
                                                </v-icon>
                                            </template>
                                            Opcja dostępna tylko dla Quizu z layoutem
                                            <b>{{ QuizLayoutLabel[QuizLayout.QL2] }}</b
                                            >. Pozwala na określenie formatu wyświetlania danych na
                                            osi Y. Aby w etykiecie umieścić wartość liczbową danego
                                            punktu wstaw tag <b>{v}</b>.
                                        </v-tooltip>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <!-- GAUGE -->
                        <template
                            v-if="edit_dialog_chart.chart_type === ContentBlockChartType.GAUGE"
                        >
                            <h4
                                class="text-body-2 text-uppercase grey--text font-weight-regular mb-2 mt-4"
                            >
                                Ustawienia wykresu miernikowego
                            </h4>
                            <v-row dense>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-select
                                        outlined
                                        label="Kąt początkowy"
                                        v-model="edit_dialog_chart.gauge.start_angle"
                                        :items="GAUGE_CHART_ANGLE_SELECT_ITEMS"
                                        hide-details="auto"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-select
                                        outlined
                                        label="Kąt końcowy"
                                        v-model="edit_dialog_chart.gauge.end_angle"
                                        :items="GAUGE_CHART_ANGLE_SELECT_ITEMS"
                                        hide-details="auto"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        outlined
                                        label="Wartość maksymalna"
                                        v-model="edit_dialog_chart.gauge.max_value"
                                        ref="edit_dialog_chart_gauge_max_value"
                                        :rules="[
                                            v => {
                                                if (!v) return 'To pole jest wymagane';
                                                if (!/^[1-9][0-9]*$/.test(v)) {
                                                    return 'Proszę podać dodatnią liczbę całkowitą';
                                                }
                                                const PV = parseInt(v);
                                                if (PV < 1) {
                                                    return 'Wartość maksymalna musi wynosić przynajmniej 1';
                                                }
                                                if (PV > 10000) {
                                                    return 'Wartość maksymalna nie może przekraczać 10000';
                                                }
                                                return true;
                                            }
                                        ]"
                                        hide-details="auto"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-select
                                        outlined
                                        label="Pozycja etykiety"
                                        v-model="edit_dialog_chart.gauge.label_offset"
                                        :items="ContentBlockChartGaugeLabelOffsetSelectItems"
                                        hide-details="auto"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-select
                                        outlined
                                        label="Wygląd wykresu"
                                        v-model="edit_dialog_chart.gauge.theme"
                                        :items="ContentBlockChartGaugeThemeSelectItems"
                                        hide-details="auto"
                                    />
                                </v-col>
                            </v-row>
                        </template>

                        <h4
                            class="text-body-2 text-uppercase grey--text font-weight-regular mb-1 mt-4"
                        >
                            Punkty danych
                            <v-tooltip
                                right
                                max-width="350"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        size="18"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-help-circle
                                    </v-icon>
                                </template>
                                W przypadku wykresów liniowego oraz powierzchniowego, podane punkty
                                danych o tym samym ID serii zostaną zgrupowane. Nie zmienia się
                                założenie o jednej osi X, w związku z czym pozycja (i etykieta) dla
                                1. punktu w ID serii "A" będzie taka sama jak pozycja 1. punktu
                                danych w ID serii "B", itd.
                                <br /><br />
                                W przypadku wykresów liniowego oraz powierzchniowego kolorem serii
                                jest kolor pierwszego punktu danych w danej serii
                            </v-tooltip>
                        </h4>
                        <div
                            v-if="edit_dialog_chart.data_points.length === 0"
                            class="pa-2 grey lighten-4 rounded text-center text-caption grey--text"
                        >
                            Nie dodano jeszcze żadnego elementu
                        </div>
                        <Container
                            @drop="onChartPointlItemDrop"
                            v-if="edit_dialog_chart.data_points.length > 0"
                        >
                            <Draggable
                                v-for="(it, index) in edit_dialog_chart.data_points"
                                :key="index"
                            >
                                <v-card
                                    class="px-4 py-2 mb-2 d-flex align-center"
                                    style="cursor: grab"
                                    outlined
                                >
                                    <v-img
                                        class="mr-2"
                                        contain
                                        width="40"
                                        max-width="40"
                                        height="40"
                                        max-height="40"
                                        :src="
                                            ['image/svg+xml', 'image/gif'].includes(
                                                it.icon.mimetype
                                            )
                                                ? it.icon.url
                                                : it.icon.url_thumb
                                        "
                                        v-if="it.icon"
                                    />
                                    <v-avatar
                                        :color="`#${it.color}`"
                                        size="16"
                                        class="mr-4"
                                        rounded
                                    ></v-avatar>
                                    <span class="font-weight-bold">
                                        {{ it.label }}
                                    </span>
                                    <span class="grey--text lighten-2 pl-4">
                                        ID serii danych: {{ it.serie_id }}
                                    </span>
                                    <v-spacer />
                                    <v-btn
                                        icon
                                        text
                                        small
                                        @click="
                                            () => {
                                                chart_edit_dialog_item = JSON.parse(
                                                    JSON.stringify(it)
                                                );
                                                chart_edit_dialog_index = index;
                                                chart_edit_dialog = true;
                                            }
                                        "
                                    >
                                        <v-icon size="20"> mdi-pencil </v-icon>
                                    </v-btn>
                                    <v-btn
                                        icon
                                        text
                                        small
                                        color="error"
                                        @click="
                                            () => {
                                                chart_delete_dialog_index = index;
                                                chart_delete_dialog = true;
                                            }
                                        "
                                    >
                                        <v-icon size="20"> mdi-delete </v-icon>
                                    </v-btn>
                                </v-card>
                            </Draggable>
                        </Container>

                        <div class="mt-2 text-right">
                            <v-btn
                                v-if="edit_dialog_chart.data_points.length < 64"
                                text
                                color="primary"
                                small
                                @click="chart_create_dialog = true"
                            >
                                <v-icon size="16">mdi-plus</v-icon>
                                Dodaj element
                            </v-btn>
                            <div
                                v-else
                                class="text-caption grey--text"
                            >
                                Dodano maksymalną ilość elementów (64)
                            </div>
                        </div>

                        <!-- CHART ITEM CREATE DIALOG -->
                        <v-dialog
                            v-model="chart_create_dialog"
                            scrollable
                            max-width="360"
                            persistent
                        >
                            <v-card>
                                <v-card-title> Dodawanie punktu danych </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text
                                    style="max-height: 75vh"
                                    class="pt-5"
                                >
                                    <v-text-field
                                        outlined
                                        label="Nazwa (etykieta)"
                                        hide-details="auto"
                                        class="mb-2"
                                        ref="new_chart_data_point_label"
                                        v-model="chart_create_dialog_label"
                                        :rules="[
                                            v => {
                                                if (!v) return 'Pole wymagane';
                                                if (v.length < 1 || v.length > 31)
                                                    return 'Nazwa powinna mieć od 1 do 31 znaków';
                                                return true;
                                            }
                                        ]"
                                    />
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="grey"
                                        text
                                        @click="chart_create_dialog = false"
                                    >
                                        Anuluj
                                    </v-btn>
                                    <v-btn
                                        color="success"
                                        text
                                        @click="createChartDataPoint"
                                    >
                                        Dodaj
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <!-- CHART ITEM EDIT DIALOG -->
                        <v-dialog
                            v-model="chart_edit_dialog"
                            scrollable
                            max-width="680"
                            persistent
                        >
                            <v-card v-if="chart_edit_dialog">
                                <v-card-title> Edycja punktu danych </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text
                                    style="max-height: 75vh"
                                    class="pt-5"
                                >
                                    <v-row dense>
                                        <v-col
                                            cols="12"
                                            md="8"
                                        >
                                            <v-text-field
                                                outlined
                                                label="Nazwa (etykieta)"
                                                hide-details="auto"
                                                class="mb-2"
                                                ref="edit_chart_data_point_label"
                                                v-model="chart_edit_dialog_item.label"
                                                :rules="[
                                                    v => {
                                                        if (!v) return 'Pole wymagane';
                                                        if (v.length < 1 || v.length > 31)
                                                            return 'Nazwa powinna mieć od 1 do 31 znaków';
                                                        return true;
                                                    }
                                                ]"
                                            />
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            md="4"
                                        >
                                            <v-text-field
                                                outlined
                                                label="ID serii danych"
                                                hide-details="auto"
                                                class="mb-2"
                                                ref="edit_chart_data_point_serie_id"
                                                v-model="chart_edit_dialog_item.serie_id"
                                                :rules="[
                                                    v => {
                                                        if (!v) return 'Pole wymagane';
                                                        if (v.length < 1 || v.length > 31)
                                                            return 'ID serii powinno mieć od 1 do 31 znaków';
                                                        return true;
                                                    }
                                                ]"
                                            />
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            md="4"
                                        >
                                            <v-text-field
                                                outlined
                                                label="Kolor"
                                                hide-details="auto"
                                                class="mb-2"
                                                ref="edit_chart_data_point_color"
                                                v-model="chart_edit_dialog_item.color"
                                                prefix="#"
                                                :rules="[
                                                    v => {
                                                        if (!v) return 'Pole wymagane';
                                                        if (
                                                            !/^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(
                                                                v
                                                            )
                                                        )
                                                            return 'Wymagany kolor w zapisie szesnastkowym';
                                                        return true;
                                                    }
                                                ]"
                                            />
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            md="8"
                                        >
                                            <WkMediaManager
                                                file-select
                                                @file="
                                                    file => {
                                                        if (
                                                            file.display_type !=
                                                            MediamanagerFileDisplayType.IMAGE
                                                        ) {
                                                            $message({
                                                                type: 'error',
                                                                msg: 'Proszę wybrać plik będący obrazem'
                                                            });
                                                        } else {
                                                            chart_edit_dialog_item = {
                                                                ...chart_edit_dialog_item,
                                                                icon: {
                                                                    url: file.url,
                                                                    url_hq: file.url_hq,
                                                                    url_thumb: file.url_thumb,
                                                                    url_placeholder:
                                                                        file.url_placeholder,
                                                                    mimetype: file.mimetype
                                                                }
                                                            };
                                                            $refs.media_manager.close();
                                                        }
                                                    }
                                                "
                                                ref="media_manager"
                                            />

                                            <v-text-field
                                                outlined
                                                readonly
                                                :value="
                                                    chart_edit_dialog_item.icon !== undefined
                                                        ? chart_edit_dialog_item.icon.url
                                                        : ''
                                                "
                                                label="Ikona serii danych"
                                                @click.prevent="$refs.media_manager.open()"
                                                hide-details
                                                class="mb-4"
                                            >
                                                <template v-slot:append>
                                                    <div
                                                        class="d-flex align-center"
                                                        :style="
                                                            chart_edit_dialog_item.icon !==
                                                            undefined
                                                                ? { transform: 'translateY(-6px)' }
                                                                : {}
                                                        "
                                                    >
                                                        <v-icon>mdi-image-search</v-icon>
                                                        <v-btn
                                                            icon
                                                            color="error"
                                                            v-if="
                                                                chart_edit_dialog_item.icon !==
                                                                undefined
                                                            "
                                                            @click.stop="
                                                                () => {
                                                                    chart_edit_dialog_item.icon =
                                                                        undefined;
                                                                }
                                                            "
                                                        >
                                                            <v-icon>mdi-close</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <h4
                                        class="text-body-2 text-uppercase grey--text font-weight-regular mb-1 mt-4"
                                    >
                                        Wartości
                                    </h4>
                                    <div
                                        v-if="chart_edit_dialog_item.values.length === 0"
                                        class="pa-2 grey lighten-4 rounded text-center text-caption grey--text"
                                    >
                                        Nie dodano jeszcze żadnej wartości
                                    </div>
                                    <v-list v-if="chart_edit_dialog_item.values.length > 0">
                                        <template>
                                            <v-data-table
                                                :headers="[
                                                    {
                                                        text: 'Wartość',
                                                        align: 'start',
                                                        sortable: false,
                                                        value: 'value'
                                                    },
                                                    {
                                                        text: 'Waga',
                                                        align: 'start',
                                                        sortable: true,
                                                        value: 'weight'
                                                    },
                                                    {
                                                        text: 'Akcje',
                                                        align: 'end',
                                                        sortable: false,
                                                        value: 'value_actions'
                                                    }
                                                ]"
                                                :items="chart_edit_dialog_item.values"
                                                :items-per-page="31"
                                                outlined
                                                hide-default-footer
                                                dense
                                            >
                                                <template
                                                    v-slot:item.value_actions="{ item, index }"
                                                >
                                                    <v-btn
                                                        icon
                                                        small
                                                        @click="
                                                            () => {
                                                                chart_value_edit_dialog_index =
                                                                    index;
                                                                chart_value_edit_dialog_item = item;
                                                                chart_value_edit_dialog = true;
                                                            }
                                                        "
                                                    >
                                                        <v-icon size="18">mdi-pencil</v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        icon
                                                        small
                                                        color="error"
                                                        @click="
                                                            () => {
                                                                chart_value_delete_dialog_index =
                                                                    index;
                                                                chart_value_delete_dialog_item =
                                                                    item;
                                                                chart_value_delete_dialog = true;
                                                            }
                                                        "
                                                    >
                                                        <v-icon size="18">mdi-delete</v-icon>
                                                    </v-btn>
                                                </template>
                                            </v-data-table>
                                        </template>
                                    </v-list>

                                    <div class="mt-2 text-right">
                                        <v-btn
                                            v-if="chart_edit_dialog_item.values.length < 31"
                                            text
                                            color="primary"
                                            small
                                            @click="
                                                () => {
                                                    chart_value_create_dialog_item = {
                                                        value: '',
                                                        weight: 1
                                                    };
                                                    chart_value_create_dialog = true;
                                                }
                                            "
                                        >
                                            <v-icon size="16">mdi-plus</v-icon>
                                            Dodaj wartość
                                        </v-btn>
                                        <div
                                            v-else
                                            class="text-caption grey--text"
                                        >
                                            Dodano maksymalną ilość wartości (31)
                                        </div>
                                    </div>

                                    <!-- VALUE CREATE DIALOG -->
                                    <v-dialog
                                        v-model="chart_value_create_dialog"
                                        max-width="360"
                                    >
                                        <v-card v-if="chart_value_create_dialog">
                                            <v-card-title> Dodawanie wartości </v-card-title>
                                            <v-card-text>
                                                <v-text-field
                                                    outlined
                                                    label="Wartość"
                                                    hide-details="auto"
                                                    class="mt-2"
                                                    ref="new_chart_value_value"
                                                    v-model="chart_value_create_dialog_item.value"
                                                    :rules="[
                                                        v => {
                                                            if (!v) return 'Pole wymagane';
                                                            if (v.length < 1 || v.length > 63)
                                                                return 'Wartość powinna mieć od 1 do 63 znaków';
                                                            return true;
                                                        }
                                                    ]"
                                                />
                                                <v-text-field
                                                    outlined
                                                    label="Waga"
                                                    type="number"
                                                    hide-details="auto"
                                                    class="mt-2"
                                                    ref="new_chart_value_weight"
                                                    v-model="chart_value_create_dialog_item.weight"
                                                    :rules="[
                                                        v => {
                                                            if (!v) return 'Pole wymagane';
                                                            if (
                                                                parseInt(v) < 1 ||
                                                                parseInt(v) > 100
                                                            )
                                                                return 'Wymagana waga z przedziału 1-100';
                                                            return true;
                                                        }
                                                    ]"
                                                />
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="grey"
                                                    text
                                                    @click="
                                                        () => {
                                                            chart_value_create_dialog = false;
                                                            chart_value_create_dialog_item = null;
                                                        }
                                                    "
                                                >
                                                    Anuluj
                                                </v-btn>
                                                <v-btn
                                                    color="success"
                                                    text
                                                    @click="createChartValue"
                                                >
                                                    Dodaj
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>

                                    <!-- VALUE EDIT DIALOG -->
                                    <v-dialog
                                        v-model="chart_value_edit_dialog"
                                        max-width="360"
                                    >
                                        <v-card v-if="chart_value_edit_dialog">
                                            <v-card-title> Edycja wartości </v-card-title>
                                            <v-card-text>
                                                <v-text-field
                                                    outlined
                                                    label="Wartość"
                                                    hide-details="auto"
                                                    class="mt-2"
                                                    ref="edit_chart_value_value"
                                                    v-model="chart_value_edit_dialog_item.value"
                                                    :rules="[
                                                        v => {
                                                            if (!v) return 'Pole wymagane';
                                                            if (v.length < 1 || v.length > 63)
                                                                return 'Wartość powinna mieć od 1 do 63 znaków';
                                                            return true;
                                                        }
                                                    ]"
                                                />
                                                <v-text-field
                                                    outlined
                                                    label="Waga"
                                                    type="number"
                                                    hide-details="auto"
                                                    class="mt-2"
                                                    ref="edit_chart_value_weight"
                                                    v-model="chart_value_edit_dialog_item.weight"
                                                    :rules="[
                                                        v => {
                                                            if (!v) return 'Pole wymagane';
                                                            if (
                                                                parseInt(v) < 1 ||
                                                                parseInt(v) > 100
                                                            )
                                                                return 'Wymagana waga z przedziału 1-100';
                                                            return true;
                                                        }
                                                    ]"
                                                />
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="grey"
                                                    text
                                                    @click="
                                                        () => {
                                                            chart_value_edit_dialog = false;
                                                            chart_value_edit_dialog_item = null;
                                                        }
                                                    "
                                                >
                                                    Anuluj
                                                </v-btn>
                                                <v-btn
                                                    color="success"
                                                    text
                                                    @click="editChartValue"
                                                >
                                                    Zapisz
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>

                                    <!-- VALUE DELETE DIALOG -->
                                    <v-dialog
                                        v-model="chart_value_delete_dialog"
                                        max-width="450"
                                    >
                                        <v-card v-if="chart_value_delete_dialog">
                                            <v-card-title> Usuwanie wartości </v-card-title>
                                            <v-card-text>
                                                Czy na pewno usunąć wartość
                                                <span class="font-weight-medium"
                                                    >{{
                                                        chart_edit_dialog_item.values[
                                                            chart_value_delete_dialog_index
                                                        ].value
                                                    }}
                                                </span>
                                                ?
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="grey"
                                                    text
                                                    @click="chart_value_delete_dialog = false"
                                                >
                                                    Anuluj
                                                </v-btn>
                                                <v-btn
                                                    color="error"
                                                    text
                                                    @click="deleteChartValue"
                                                >
                                                    Tak, usuń
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="grey"
                                        text
                                        @click="chart_edit_dialog = false"
                                    >
                                        Anuluj
                                    </v-btn>
                                    <v-btn
                                        color="success"
                                        text
                                        @click="editChartDataPoint"
                                    >
                                        Zapisz
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <!-- CHART ITEM DELETE DIALOG -->
                        <v-dialog
                            v-model="chart_delete_dialog"
                            max-width="450"
                        >
                            <v-card v-if="chart_delete_dialog">
                                <v-card-title> Usuwanie punktu danych </v-card-title>
                                <v-card-text>
                                    Czy na pewno usunąć punkt
                                    <span class="font-weight-medium">{{
                                        edit_dialog_chart.data_points[chart_delete_dialog_index]
                                            .label
                                    }}</span
                                    >?
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="grey"
                                        text
                                        @click="chart_delete_dialog = false"
                                    >
                                        Anuluj
                                    </v-btn>
                                    <v-btn
                                        color="error"
                                        text
                                        @click="deleteChartDataPoint"
                                    >
                                        Tak, usuń
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </template>
                    <!-- ROW 2 COL -->
                    <template
                        v-else-if="
                            edit_dialog_block.block_type == ContentBlockType.ROW_2COL &&
                            edit_dialog_row_2col
                        "
                    >
                        <v-row dense>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-select
                                    outlined
                                    label="Layout wiersza"
                                    v-model="edit_dialog_row_2col.layout"
                                    :items="ContentBlockRow2ColLayoutSelectItems"
                                    hide-details="auto"
                                />
                            </v-col>
                        </v-row>
                    </template>
                    <!-- VIMEO VIDEO -->
                    <template
                        v-else-if="
                            edit_dialog_block.block_type == ContentBlockType.VIMEO_VIDEO &&
                            edit_dialog_vimeo_video
                        "
                    >
                        <v-row dense>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    outlined
                                    label="ID video"
                                    type="text"
                                    v-model="edit_dialog_vimeo_video.id"
                                    ref="edit_dialog_vimeo_video_id"
                                    hide-details="auto"
                                    :rules="[
                                        v => {
                                            if (!v || v.length == 0) return 'Pole wymagane';
                                            if (v.length > 24) {
                                                return 'Maksymalna długość pola to 24 znaki';
                                            }
                                            return true;
                                        }
                                    ]"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    outlined
                                    label="Hash dostępowy (h)"
                                    type="text"
                                    v-model="edit_dialog_vimeo_video.hash"
                                    ref="edit_dialog_vimeo_video_hash"
                                    hide-details="auto"
                                    :rules="[
                                        v => {
                                            if (!v || v.length == 0) return true;
                                            if (v.length > 48) {
                                                return 'Maksymalna długość pola to 48 znaków';
                                            }
                                            return true;
                                        }
                                    ]"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    outlined
                                    label="Czas rozpoczęcia filmu ({n}m{n}s)"
                                    type="text"
                                    v-model="edit_dialog_vimeo_video.start_time"
                                    ref="edit_dialog_vimeo_video_start_time"
                                    hide-details="auto"
                                    :rules="[
                                        v => {
                                            if (!v || v.length == 0) return true;
                                            if (v.length > 16) {
                                                return 'Maksymalna długość pola to 16 znaków';
                                            }
                                            return true;
                                        }
                                    ]"
                                />
                            </v-col>
                        </v-row>
                    </template>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="grey lighten-1"
                        :disabled="edit_dialog_loading"
                        @click="closeEditDialog"
                    >
                        Anuluj
                    </v-btn>
                    <v-btn
                        text
                        color="success"
                        :loading="edit_dialog_loading"
                        @click="editBlock"
                    >
                        Zapisz
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- DELETE DIALOG -->
        <v-dialog
            v-model="delete_dialog"
            max-width="400"
            scrollable
        >
            <v-card v-if="delete_dialog_block">
                <v-card-title> Usuwanie bloku </v-card-title>
                <v-divider></v-divider>
                <v-card-text
                    class="pt-5"
                    style="max-height: 70vh"
                >
                    Czy na pewno usunąć ten blok treści? Tej operacji <b>nie można cofnąć</b>!

                    <div class="mt-2 text-caption grey--text">
                        ID: {{ delete_dialog_block.oid }}, typ:
                        {{ ContentBlockTypeLabel[delete_dialog_block.block_type] }}
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="grey lighten-1"
                        :disabled="delete_dialog_loading"
                        @click="delete_dialog = false"
                    >
                        Anuluj
                    </v-btn>
                    <v-btn
                        text
                        color="error"
                        :loading="delete_dialog_loading"
                        @click="deleteBlock"
                    >
                        Usuń
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { nanoid } from "nanoid";
import { Container, Draggable } from "vue-smooth-dnd";
import { MediamanagerFileDisplayType } from "../../enums/Mediamanager";
import {
    ContentBlockType,
    ContentBlockTypeLabel,
    ContentBlockTypeSelectItems,
    ContentBlockAnimatedSummaryListItemIconName,
    ContentBlockChartType,
    ContentBlockChartTypeSelectItems,
    ContentBlockRow2ColLayout,
    ContentBlockRow2ColLayoutSelectItems,
    ContentBlockChartGaugeLabelOffset,
    ContentBlockChartGaugeTheme,
    ContentBlockChartGaugeLabelOffsetSelectItems,
    ContentBlockChartGaugeThemeSelectItems
} from "../../enums/ContentBlocks";
import TipTapWysiwyg from "../TipTapWysiwyg/TipTapWysiwyg.vue";
import WkMediaManager from "../WkMediaManager/WkMediaManager";
import ContentBlockAnimatedSummaryListForm from "./ContentBlockAnimatedSummaryListForm";
import { getPossibleLogicExpressionOutputs } from "../../helpers/quiz-logic-expressions";
import { QuizLayout, QuizLayoutLabel } from "../../enums/Quiz";

const GAUGE_CHART_ANGLE_SELECT_ITEMS = [
    {
        value: -270,
        text: "-270°"
    },
    {
        value: -240,
        text: "-240°"
    },
    {
        value: -210,
        text: "-210°"
    },
    {
        value: -180,
        text: "-180°"
    },
    {
        value: -150,
        text: "-150°"
    },
    {
        value: -120,
        text: "-120°"
    },
    {
        value: -90,
        text: "-90°"
    },
    {
        value: -60,
        text: "-60°"
    },
    {
        value: -30,
        text: "-30°"
    },
    {
        value: 0,
        text: "0°"
    },
    {
        value: 30,
        text: "30°"
    },
    {
        value: 60,
        text: "60°"
    },
    {
        value: 90,
        text: "90°"
    },
    {
        value: 120,
        text: "120°"
    },
    {
        value: 150,
        text: "150°"
    },
    {
        value: 180,
        text: "180°"
    },
    {
        value: 210,
        text: "210°"
    },
    {
        value: 240,
        text: "240°"
    },
    {
        value: 270,
        text: "270°"
    },
    {
        value: 300,
        text: "300°"
    },
    {
        value: 330,
        text: "330°"
    },
    {
        value: 360,
        text: "360°"
    }
];

export default {
    name: "ContentBlockEditor",

    components: {
        Container,
        Draggable,
        WkMediaManager,
        TipTapWysiwyg,
        ContentBlockAnimatedSummaryListForm
    },

    props: {
        value: {
            type: Array,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        reorderDisabled: {
            type: Boolean,
            default: false
        },
        quizContext: {
            type: Boolean,
            default: false
        },
        quizBoards: {
            type: Array,
            default() {
                return [];
            }
        },
        blocksFilter: {
            type: Number,
            default: 0
        },
        disallowedBlockTypes: {
            type: Array,
            default() {
                return [];
            }
        }
    },

    data() {
        return {
            ld: [],

            create_dialog: false,
            create_dialog_loading: false,
            create_dialog_block_type: ContentBlockType.WYSIWYG,
            create_dialog_index: 0,

            edit_dialog: false,
            edit_dialog_just_created: false,
            edit_dialog_loading: false,
            edit_dialog_block: null,
            edit_dialog_wysiwyg: null,
            edit_dialog_image: null,
            edit_dialog_asl: null,
            edit_dialog_chart: null,
            edit_dialog_row_2col: null,
            edit_dialog_vimeo_video: null,

            delete_dialog: false,
            delete_dialog_loading: false,
            delete_dialog_block: null,

            asl_create_dialog: false,

            asl_edit_dialog: false,
            asl_edit_dialog_item: null,
            asl_edit_dialog_index: -1,

            asl_delete_dialog: false,
            asl_delete_dialog_index: -1,

            chart_create_dialog: null,
            chart_create_dialog_label: "",

            chart_edit_dialog: false,
            chart_edit_dialog_item: null,
            chart_edit_dialog_index: -1,

            chart_delete_dialog: false,
            chart_delete_dialog_index: -1,

            chart_value_create_dialog: false,
            chart_value_create_dialog_item: null,

            chart_value_edit_dialog: false,
            chart_value_edit_dialog_index: -1,

            chart_value_delete_dialog: false,
            chart_value_delete_dialog_index: -1,

            ctx_menu: false,
            ctx_menu_x: 0,
            ctx_menu_y: 0,
            ctx_menu_ix: 0,

            QuizLayout,
            QuizLayoutLabel,

            ContentBlockType,
            ContentBlockTypeSelectItems,
            ContentBlockTypeLabel,
            ContentBlockAnimatedSummaryListItemIconName,
            ContentBlockChartType,
            ContentBlockChartTypeSelectItems,
            ContentBlockRow2ColLayoutSelectItems,
            ContentBlockChartGaugeLabelOffsetSelectItems,
            ContentBlockChartGaugeThemeSelectItems,

            MediamanagerFileDisplayType,

            getPossibleLogicExpressionOutputs,

            GAUGE_CHART_ANGLE_SELECT_ITEMS
        };
    },

    computed: {
        wysiwyg_editor_extensions() {
            const A = [
                "bold",
                "bullet-list",
                "blockquote",
                "color",
                "font-size",
                "hard-break",
                "heading",
                "highlight",
                "history",
                "italic",
                "link",
                "ordered-list",
                "strike",
                "subscript",
                "superscript",
                "text-align",
                "underline"
            ];

            if (this.quizContext) {
                A.push("quiz-le");
                A.push("quiz-personalization");
            }

            return A;
        },

        create_dialog_block_type_select_items() {
            const SI = JSON.parse(JSON.stringify(ContentBlockTypeSelectItems));

            for (let i = 0; i < this.disallowedBlockTypes.length; i++) {
                const IX = SI.findIndex(it => it.value === this.disallowedBlockTypes[i]);
                if (IX !== -1) {
                    SI.splice(IX, 1);
                }
            }

            return SI;
        },

        selected_breakpoints() {
            if (this.blocksFilter === 0) {
                return ["sm", "md", "lg"];
            }
            if (this.blocksFilter === 1) {
                return ["sm"];
            }
            if (this.blocksFilter === 2) {
                return ["md"];
            }
            if (this.blocksFilter === 3) {
                return ["lg"];
            }
        },

        wide_cbe_layout() {
            return this.ld.findIndex(it => it.block_type === ContentBlockType.ROW_2COL) !== -1;
        }
    },

    watch: {
        value: {
            handler() {
                this.ld = JSON.parse(JSON.stringify(this.value));
            },
            immediate: true
        }
    },

    methods: {
        canShowBlock(block) {
            const SELECTED_BREAKPOINTS = this.selected_breakpoints;
            for (let i = 0; i < SELECTED_BREAKPOINTS.length; i++) {
                if (
                    (SELECTED_BREAKPOINTS[i] === "sm" && block.visible_sm !== false) ||
                    (SELECTED_BREAKPOINTS[i] === "md" && block.visible_md !== false) ||
                    (SELECTED_BREAKPOINTS[i] === "lg" && block.visible_lg !== false)
                ) {
                    return true;
                }
            }

            return false;
        },

        emitUpdate() {
            const D = JSON.parse(JSON.stringify(this.ld));
            this.$emit("input", D);
            this.$emit("change", D);
            this.$emit("update");
        },

        hideAllCtxMenus() {
            this.ctx_menu = false;
        },
        showContextMenu(e, ix) {
            if (this.disabled) return;
            e.preventDefault();

            this.hideAllCtxMenus();
            this.ctx_menu_x = e.clientX;
            this.ctx_menu_y = e.clientY;
            this.ctx_menu_ix = ix;
            this.$nextTick(() => {
                this.ctx_menu = true;
            });
        },

        createBlock() {
            if (this.create_dialog_loading) return;

            if (this.ld.length >= 32) {
                this.$message({
                    type: "error",
                    msg: "Każda lista bloków treści może zawierać maksymalnie 32 elementy"
                });
                return;
            }

            this.create_dialog_loading = true;

            const O = {
                oid: nanoid(),
                block_type: this.create_dialog_block_type,
                visible_sm: true,
                visible_md: true,
                visible_lg: true
            };
            if (O.block_type == ContentBlockType.WYSIWYG) {
                O.wysiwyg = {
                    content: ""
                };
            } else if (O.block_type == ContentBlockType.IMAGE) {
                O.image = {
                    alt: "",
                    caption: ""
                };
            } else if (O.block_type == ContentBlockType.ANIMATED_SUMMARY_LIST) {
                O.animated_summary_list = {
                    items: []
                };
            } else if (O.block_type == ContentBlockType.CHART) {
                O.chart = {
                    chart_type: ContentBlockChartType.LINE,
                    height: 480,
                    margin_top: 0,
                    margin_bottom: 0,
                    data_points: []
                };
            } else if (O.block_type === ContentBlockType.ROW_2COL) {
                O.row_2col = {
                    layout: ContentBlockRow2ColLayout._50_50,
                    blocks_col1: [],
                    blocks_col2: []
                };
            } else if (O.block_type === ContentBlockType.VIMEO_VIDEO) {
                O.vimeo_video = {
                    id: "",
                    hash: "",
                    start_time: ""
                };
            }

            this.ld.splice(this.create_dialog_index, 0, O);

            this.create_dialog = false;

            // opening edit dialog
            if (
                ![
                    ContentBlockType.QUIZ_BOARD_PREDEFINED_CONTENT,
                    ContentBlockType.QUIZ_NEXT_BOARD_BUTTON_PLACEHOLDER
                ].includes(O.block_type)
            ) {
                this.openEditDialog(this.ld[this.create_dialog_index], true);
            } else {
                this.emitUpdate();
            }

            this.create_dialog_loading = false;
        },

        openEditDialog(block, just_created = false) {
            this.edit_dialog_block = block;
            this.edit_dialog_just_created = just_created;

            if (block.block_type == ContentBlockType.WYSIWYG) {
                this.edit_dialog_wysiwyg = JSON.parse(JSON.stringify(block.wysiwyg));
            } else if (block.block_type == ContentBlockType.IMAGE) {
                this.edit_dialog_image = JSON.parse(JSON.stringify(block.image));
            } else if (block.block_type == ContentBlockType.ANIMATED_SUMMARY_LIST) {
                this.edit_dialog_asl = JSON.parse(JSON.stringify(block.animated_summary_list));
            } else if (block.block_type == ContentBlockType.CHART) {
                this.edit_dialog_chart = JSON.parse(JSON.stringify(block.chart));
            } else if (block.block_type == ContentBlockType.ROW_2COL) {
                this.edit_dialog_row_2col = JSON.parse(JSON.stringify(block.row_2col));
            } else if (block.block_type == ContentBlockType.VIMEO_VIDEO) {
                this.edit_dialog_vimeo_video = JSON.parse(JSON.stringify(block.vimeo_video));
            }

            const VISIBILITY_PROPS = ["visible_sm", "visible_md", "visible_lg"];
            for (let i = 0; i < VISIBILITY_PROPS.length; i++) {
                if (this.edit_dialog_block[VISIBILITY_PROPS[i]] === undefined) {
                    this.edit_dialog_block[VISIBILITY_PROPS[i]] = true;
                }
            }

            this.edit_dialog = true;
        },
        closeEditDialog() {
            if (this.edit_dialog_just_created) {
                const ix = this.ld.findIndex(it => it.oid == this.edit_dialog_block.oid);
                if (ix !== -1) {
                    this.ld.splice(ix, 1);
                }
            }

            this.edit_dialog = false;
        },
        editBlock() {
            if (this.edit_dialog_loading) return;

            const A = [];

            if (this.edit_dialog_block.block_type == ContentBlockType.IMAGE) {
                const REQ_IMG_KEYS = ["mimetype", "url", "url_hq", "url_placeholder", "url_thumb"];
                for (let i = 0; i < REQ_IMG_KEYS.length; i++) {
                    if (!this.edit_dialog_image[REQ_IMG_KEYS[i]]) {
                        A.push(false);
                        this.$message({
                            type: "error",
                            msg: "Proszę wybrać plik"
                        });
                        break;
                    }
                }

                A.push(this.$refs.edit_dialog_image_caption.validate(true));
                A.push(this.$refs.edit_dialog_image_alt.validate(true));
            } else if (this.edit_dialog_block.block_type == ContentBlockType.CHART) {
                A.push(this.$refs.edit_dialog_chart_height.validate(true));
                A.push(this.$refs.edit_dialog_chart_margin_top.validate(true));
                A.push(this.$refs.edit_dialog_chart_margin_bottom.validate(true));
                A.push(this.$refs.edit_dialog_chart_yaxis_label_format.validate(true));

                if (this.edit_dialog_chart.chart_type === ContentBlockChartType.GAUGE) {
                    A.push(this.$refs.edit_dialog_chart_gauge_max_value.validate(true));
                }
            } else if (this.edit_dialog_block.block_type == ContentBlockType.VIMEO_VIDEO) {
                A.push(this.$refs.edit_dialog_vimeo_video_id.validate(true));
                A.push(this.$refs.edit_dialog_vimeo_video_hash.validate(true));
                A.push(this.$refs.edit_dialog_vimeo_video_start_time.validate(true));
            }

            if (A.indexOf(false) !== -1) return;

            this.edit_dialog_loading = true;

            const ix = this.ld.findIndex(it => it.oid == this.edit_dialog_block.oid);
            if (ix !== -1) {
                const UPD = {
                    visible_sm: this.edit_dialog_block.visible_sm,
                    visible_md: this.edit_dialog_block.visible_md,
                    visible_lg: this.edit_dialog_block.visible_lg
                };

                if (this.edit_dialog_block.block_type == ContentBlockType.IMAGE) {
                    UPD.image = JSON.parse(JSON.stringify(this.edit_dialog_image));
                } else if (this.edit_dialog_block.block_type == ContentBlockType.WYSIWYG) {
                    UPD.wysiwyg = JSON.parse(JSON.stringify(this.edit_dialog_wysiwyg));
                } else if (
                    this.edit_dialog_block.block_type == ContentBlockType.ANIMATED_SUMMARY_LIST
                ) {
                    UPD.animated_summary_list = JSON.parse(JSON.stringify(this.edit_dialog_asl));
                } else if (this.edit_dialog_block.block_type == ContentBlockType.CHART) {
                    UPD.chart = JSON.parse(JSON.stringify(this.edit_dialog_chart));
                } else if (this.edit_dialog_block.block_type == ContentBlockType.ROW_2COL) {
                    UPD.row_2col = JSON.parse(JSON.stringify(this.edit_dialog_row_2col));
                } else if (this.edit_dialog_block.block_type == ContentBlockType.VIMEO_VIDEO) {
                    UPD.vimeo_video = JSON.parse(JSON.stringify(this.edit_dialog_vimeo_video));
                }

                this.ld.splice(ix, 1, {
                    ...this.ld[ix],
                    ...UPD
                });
                this.emitUpdate();
            }
            this.edit_dialog = false;
            this.edit_dialog_wysiwyg = null;
            this.edit_dialog_image = null;

            this.edit_dialog_loading = false;
        },
        onChartTypeChangeInEditDialog() {
            delete this.edit_dialog_chart.gauge;

            if (this.edit_dialog_chart.chart_type === ContentBlockChartType.GAUGE) {
                this.edit_dialog_chart.gauge = {
                    start_angle: -90,
                    end_angle: 90,
                    theme: ContentBlockChartGaugeTheme.QL1_DEFAULT,
                    max_value: 100,
                    label_offset: ContentBlockChartGaugeLabelOffset.CENTER
                };
            }
        },

        deleteBlock() {
            if (this.delete_dialog_loading) return;
            this.delete_dialog_loading = true;

            const ix = this.ld.findIndex(it => it.oid == this.delete_dialog_block.oid);
            if (ix !== -1) {
                this.ld.splice(ix, 1);
                this.emitUpdate();
            }
            this.delete_dialog = false;

            this.delete_dialog_loading = false;
        },

        onCbReorder(e) {
            if (e.removedIndex == e.addedIndex) return;

            this.ld.splice(e.addedIndex, 0, this.ld.splice(e.removedIndex, 1)[0]);
            this.emitUpdate();
        },
        onBlockMoveRequest(ix, dir) {
            this.onCbReorder({ removedIndex: ix, addedIndex: ix + dir });
        },

        // ANIMATED SUMMARY LIST
        createAslItem() {
            if (!this.$refs.asl_create_dialog_form.validate()) return;
            this.edit_dialog_asl.items.push(this.$refs.asl_create_dialog_form.getData());
            this.asl_create_dialog = false;
        },
        editAslItem() {
            if (!this.$refs.asl_edit_dialog_form.validate()) return;
            this.edit_dialog_asl.items.splice(
                this.asl_edit_dialog_index,
                1,
                this.$refs.asl_edit_dialog_form.getData()
            );
            this.asl_edit_dialog_item = null;
            this.asl_edit_dialog_index = -1;
            this.asl_edit_dialog = false;
        },
        deleteAslItem() {
            this.edit_dialog_asl.items.splice(this.asl_delete_dialog_index, 1);
            this.asl_delete_dialog = false;
        },
        onAslItemDrop(drop_event) {
            if (
                drop_event.removedIndex !== null &&
                drop_event.addedIndex !== null &&
                drop_event.addedIndex != drop_event.removedIndex
            ) {
                const [removedItem] = this.edit_dialog_asl.items.splice(drop_event.removedIndex, 1);
                this.edit_dialog_asl.items.splice(drop_event.addedIndex, 0, removedItem);
            }
        },

        // CHART
        createChartDataPoint() {
            if (!this.$refs.new_chart_data_point_label.validate()) return;
            const DP = {
                label: this.chart_create_dialog_label,
                color: "30A47A",
                values: []
            };
            if (this.edit_dialog_chart.data_points.length == 0) {
                DP.serie_id = "1";
            } else {
                DP.serie_id =
                    this.edit_dialog_chart.data_points[
                        this.edit_dialog_chart.data_points.length - 1
                    ].serie_id;
            }
            this.edit_dialog_chart.data_points.push(DP);
            this.chart_create_dialog = false;
        },
        editChartDataPoint() {
            if (!this.$refs.edit_chart_data_point_label.validate()) return;
            if (!this.$refs.edit_chart_data_point_color.validate()) return;
            if (!this.$refs.edit_chart_data_point_serie_id.validate()) return;

            this.edit_dialog_chart.data_points.splice(
                this.chart_edit_dialog_index,
                1,
                this.chart_edit_dialog_item
            );

            this.chart_edit_dialog_index = -1;
            this.chart_edit_dialog_item = null;
            this.chart_edit_dialog = false;
        },
        deleteChartDataPoint() {
            this.edit_dialog_chart.data_points.splice(this.chart_delete_dialog_index, 1);
            this.chart_delete_dialog = false;
        },
        onChartPointlItemDrop(drop_event) {
            if (
                drop_event.removedIndex !== null &&
                drop_event.addedIndex !== null &&
                drop_event.addedIndex != drop_event.removedIndex
            ) {
                const [removedItem] = this.edit_dialog_chart.data_points.splice(
                    drop_event.removedIndex,
                    1
                );
                this.edit_dialog_chart.data_points.splice(drop_event.addedIndex, 0, removedItem);
            }
        },
        createChartValue() {
            if (!this.$refs.new_chart_value_value.validate()) return;
            if (!this.$refs.new_chart_value_weight.validate()) return;

            this.chart_edit_dialog_item.values.push(this.chart_value_create_dialog_item);

            this.chart_value_create_dialog_item = null;
            this.chart_value_create_dialog = false;
        },
        editChartValue() {
            if (!this.$refs.edit_chart_value_value.validate()) return;
            if (!this.$refs.edit_chart_value_weight.validate()) return;

            this.chart_edit_dialog_item.values[this.chart_value_edit_dialog_index] =
                this.chart_value_edit_dialog_item;

            this.chart_value_edit_dialog_index = -1;
            this.chart_value_edit_dialog_item = null;
            this.chart_value_edit_dialog = false;
        },
        deleteChartValue() {
            this.chart_edit_dialog_item.values.splice(this.chart_value_delete_dialog_index, 1);
            this.chart_value_delete_dialog_index = -1;
            this.chart_value_delete_dialog = false;
        }
    }
};
</script>
