<template>
    <v-dialog
        v-model="iv"
        max-width="500px"
        persistent
        scrollable
    >
        <v-card>
            <v-card-title class="pa-4 pb-2"> Wybierz kategorię </v-card-title>
            <v-card-text
                style="max-height: 70vh"
                class="px-4"
                v-if="iv"
            >
                <DevProgramsEpisodeCategoriesManager
                    :readonly="readonly"
                    :category-id="initialCategoryId"
                    @selection="onCategorySelection"
                />
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="d-block">
                <div
                    class="mb-2 text-body-2 pt-2"
                    v-if="selected_category"
                >
                    <span class="grey--text">Wybrana kategoria: </span> {{ selected_category.name }}
                </div>
                <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="grey"
                        text
                        @click="$emit('input', false)"
                    >
                        Anuluj
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        :disabled="!selected_category"
                        @click="$emit('category-selected', selected_category)"
                    >
                        {{ confirmationButtonText }}
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: "DevProgramsEpisodeCategorySelector",

    components: {},

    props: {
        value: {
            type: Boolean,
            required: true
        },
        readonly: {
            type: Boolean,
            default: false
        },
        initialCategoryId: {
            type: String
        },
        confirmationButtonText: {
            type: String,
            default: "Przenieś"
        }
    },

    data() {
        return {
            iv: false,
            selected_category: null
        };
    },

    watch: {
        value: {
            handler() {
                this.$nextTick(() => {
                    this.iv = this.value;

                    if (this.value === false) {
                        this.selected_category = null;
                    }
                });
            },
            immediate: true
        }
    },

    methods: {
        onCategorySelection(pl) {
            this.selected_category = pl;
        }
    }
};
</script>
