"use strict";

export const PaymentIntentCurrency = Object.freeze({
    PLN: "pln",
    EUR: "eur"
});
export const PaymentIntentCurrencyLabel = Object.freeze({
    [PaymentIntentCurrency.PLN]: "zł",
    [PaymentIntentCurrency.EUR]: "€"
});
export const PaymentIntentCurrencySelectItems = Object.values(PaymentIntentCurrency).map(it => {
    return {
        value: it,
        text: PaymentIntentCurrencyLabel[it]
    };
});

export const PaymentIntentPaymentMethod = Object.freeze({
    STRIPE_CARD_ON_SESSION: "stripe_card_on_session",
    STRIPE_P24: "stripe_p24",
    P24: "p24",
    P24_BLIK: "p24_blik"
});
export const PaymentIntentPaymentMethodLabel = Object.freeze({
    [PaymentIntentPaymentMethod.STRIPE_CARD_ON_SESSION]: "Płatność kartą online",
    [PaymentIntentPaymentMethod.STRIPE_P24]: "Przelewy24 (Stripe)",
    [PaymentIntentPaymentMethod.P24]: "Przelewy24",
    [PaymentIntentPaymentMethod.P24_BLIK]: "BLIK (Przelewy24)"
});
export const PaymentIntentPaymentMethodSelectItems = Object.values(PaymentIntentPaymentMethod).map(
    it => {
        return {
            value: it,
            text: PaymentIntentPaymentMethodLabel[it]
        };
    }
);

export const PaymentIntentStatus = Object.freeze({
    UNPAID: "unpaid",
    PAID: "paid",
    ERROR: "error",
    CANCELED: "canceled"
});
export const PaymentIntentStatusLabel = Object.freeze({
    [PaymentIntentStatus.UNPAID]: "Nieopłacone",
    [PaymentIntentStatus.PAID]: "Opłacone",
    [PaymentIntentStatus.ERROR]: "Błąd",
    [PaymentIntentStatus.CANCELED]: "Anulowane"
});
export const PaymentIntentStatusColor = Object.freeze({
    [PaymentIntentStatus.UNPAID]: "secondary",
    [PaymentIntentStatus.PAID]: "success",
    [PaymentIntentStatus.ERROR]: "error",
    [PaymentIntentStatus.CANCELED]: "lightgray"
});
export const PaymentIntentStatusSelectItems = Object.values(PaymentIntentStatus).map(it => {
    return {
        value: it,
        text: PaymentIntentStatusLabel[it]
    };
});

export const PaymentIntentSubjectType = Object.freeze({
    CHECKOUT_SESSION: "checkout_session",
    STANDALONE_CHECKOUT_SESSION: "standalone_checkout_session",
    VOID: "void"
});
export const PaymentIntentSubjectTypeLabel = Object.freeze({
    [PaymentIntentSubjectType.CHECKOUT_SESSION]: "Checkout session",
    [PaymentIntentSubjectType.STANDALONE_CHECKOUT_SESSION]: "Standalone checkout session",
    [PaymentIntentSubjectType.VOID]: "Brak"
});
export const PaymentIntentSubjectTypeColor = Object.freeze({
    [PaymentIntentSubjectType.CHECKOUT_SESSION]: "primary",
    [PaymentIntentSubjectType.STANDALONE_CHECKOUT_SESSION]: "secondary",
    [PaymentIntentSubjectType.VOID]: "#EBEBEB"
});
export const PaymentIntentSubjectTypeSelectItems = Object.values(PaymentIntentSubjectType).map(
    it => {
        return {
            value: it,
            text: PaymentIntentSubjectTypeLabel[it]
        };
    }
);
export const PaymentIntentSubjectTypeChip = Object.values(PaymentIntentSubjectType).reduce(
    (acc, v) => {
        acc[v] = {
            value: v,
            label: PaymentIntentSubjectTypeLabel[v],
            color: PaymentIntentSubjectTypeColor[v]
        };
        return acc;
    },
    {}
);

export const PaymentIntentInvoiceProvider = Object.freeze({
    FAKTUROWNIA: "fakturownia"
});
export const PaymentIntentInvoiceProviderLabel = Object.freeze({
    [PaymentIntentInvoiceProvider.FAKTUROWNIA]: "Fakturownia"
});
