const CONFIG = Object.freeze({
    appointments: {
        endpoint: "/appointments/${id}",
        response_key: "appointment"
    },
    users: {
        endpoint: "/users/${id}",
        response_key: "user"
    },
    arp: {
        endpoint: "/appointment-reschedule-proposals/${id}",
        response_key: "appointment_reschedule_proposal"
    },
    products: {
        endpoint: "/products/${id}",
        response_key: "product"
    },
    user_segments: {
        endpoint: "/user-segments/${id}",
        response_key: "user_segment"
    },
    services: {
        endpoint: "/services/${id}",
        response_key: "service"
    },
    spe_categories: {
        endpoint: "/selfdev-program-episode-categories/${id}",
        response_key: "selfdev_program_episode_category"
    },
    spe_episodes: {
        endpoint: "/selfdev-program-episodes/${id}",
        response_key: "selfdev_program_episode"
    },
    surveys: {
        endpoint: "/surveys/${id}",
        response_key: "survey"
    },
    selfdev_programs: {
        endpoint: "/selfdev-programs/${id}",
        response_key: "selfdev_program"
    },
    document_templates: {
        endpoint: "/document-templates/${id}",
        response_key: "document_template"
    },
    payment_intents: {
        endpoint: "/payment-intents/${id}",
        response_key: "payment_intent"
    },
    referral_partners: {
        endpoint: "/referral-partners/${id}",
        response_key: "referral_partner"
    }
});

export default {
    namespaced: true,

    state: () => ({
        data: {},
        fetch_map: new Map()
    }),

    getters: {
        retrieveElement: state => (type, id) => {
            if (!state.data[type]) return null;
            return state.data[type].find(it => it._id === id);
        }
    },

    mutations: {
        updateOrInsertElement(state, { type, item }) {
            if (!state.data[type]) {
                state.data = {
                    ...state.data,
                    [type]: []
                };
            }
            const ix = state.data[type].findIndex(it => it._id === item._id);
            if (ix === -1) {
                state.data = {
                    ...state.data,
                    [type]: state.data[type].concat([item])
                };
            } else {
                const NA = JSON.parse(JSON.stringify(state.data[type]));
                NA.splice(ix, 1, { ...state.data[type][ix], ...item });

                state.data = {
                    ...state.data,
                    [type]: NA
                };
            }
        },

        nuke(state, type) {
            if (type) {
                state.data = {
                    ...state.data,
                    [type]: []
                };
                state.fetch_map.delete(type);
            } else {
                state.data = {};
                state.fetch_map.clear();
            }
        }
    },

    actions: {
        async getElement({ state, commit, getters }, { type, id }) {
            const type_config = CONFIG[type];
            if (!type_config) {
                throw new Error(`Configuration for type '${type}' not found`);
            }

            const endpoint = type_config.endpoint.replace("${id}", id);

            if (!state.fetch_map.has(type)) {
                state.fetch_map.set(type, new Map());
            }
            const type_fetch_map = state.fetch_map.get(type);

            if (!state.data[type]) {
                state.data = {
                    ...state.data,
                    [type]: []
                };
            }

            const item = getters["retrieveElement"](type, id);
            if (item) return item;

            if (type_fetch_map.has(id)) {
                return await type_fetch_map.get(id);
            }

            const fetch_promise = new Promise(async (resolve, reject) => {
                try {
                    const response = await this._vm.$axios.$get(endpoint, {
                        supress_errors: true
                    });

                    const fetched_item = response[type_config.response_key];
                    if (!fetched_item) {
                        throw new Error(`Response key '${type_config.response_key}' not found`);
                    }

                    commit("updateOrInsertElement", { type, item: fetched_item });
                    resolve(fetched_item);
                } catch (error) {
                    reject(error);
                } finally {
                    type_fetch_map.delete(id);
                }
            });

            type_fetch_map.set(id, fetch_promise);
            return await fetch_promise;
        }
    }
};
