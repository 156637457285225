<template>
    <v-app>
        <LayoutMessages />
        <AutoUpdater />
        <component
            :is="layout"
            :key="layout"
        >
            <router-view
                :layout.sync="layout"
                :key="$route.fullPath"
            />

            <v-overlay
                :value="!online_status"
                :opacity="0.75"
                z-index="200"
            >
                <div>
                    <div style="display: flex; justify-content: center">
                        <v-progress-circular
                            indeterminate
                            size="64"
                            color="info"
                        ></v-progress-circular>
                    </div>
                    <div class="text-center mt-4">
                        Aplikacja utraciła połączenie z internetem
                        <br />
                        Oczekiwanie na ponowne połączenie...
                    </div>
                </div>
            </v-overlay>
            <v-overlay
                :value="!appBooted"
                :opacity="0.75"
            >
                <div>
                    <div style="display: flex; justify-content: center">
                        <v-progress-circular
                            indeterminate
                            size="64"
                            color="secondary"
                        ></v-progress-circular>
                    </div>
                    <div class="text-center mt-4">Trwa ładowanie aplikacji...</div>
                </div>
            </v-overlay>
        </component>
    </v-app>
</template>

<script>
import LayoutMessages from "@/components/LayoutMessages";
import AutoUpdater from "@/components/AutoUpdater";

export default {
    name: "App",

    components: {
        LayoutMessages,
        AutoUpdater
    },

    data: () => ({
        layout: "div",
        online_status: true
    }),

    computed: {
        appBooted() {
            return this.$store.state.app_booted;
        }
    },

    watch: {
        layout(nv, ov) {
            if (ov === "div") {
                this.$store.state.initial_layout_set = true;
            }
        }
    },

    methods: {
        async testConnection(afterLost = false) {
            try {
                await this.$axios.$get("/test");

                if (afterLost) {
                    this.$store.dispatch("addMessage", {
                        type: "success",
                        msg: "Aplikacja odzyskała połączenie z internetem"
                    });
                }

                this.onConnectionStatusChange(true);
            } catch (err) {
                console.error(err);

                this.onConnectionStatusChange(false);

                setTimeout(() => {
                    if (!this.online_status) this.testConnection(afterLost);
                }, 5000);
            }
        },

        onConnectionStatusChange(status = true) {
            this.online_status = status;

            if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage({
                    type: SW_CUSTOM_MESSAGES.ONLINE_STATUS_CHANGE,
                    online: status
                });
            }
        }
    },

    async created() {
        /*############################################
        ### OBSŁUGA UTRATY I ODZYSKANIA POŁĄCZENIA ###
        ############################################*/
        this.testConnection();
        window.addEventListener("online", () => {
            this.testConnection(true);
        });
        window.addEventListener("offline", () => {
            this.onConnectionStatusChange(false);
        });
    },

    metaInfo: {
        title: "Dashboard",
        titleTemplate: "%s | CMS Risify",

        htmlAttrs: {
            lang: "pl"
        }
    }
};
</script>
