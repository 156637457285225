export const StorageKeys = Object.freeze({
    AUTH_USER: "Auth.User",
    AUTH_AD: "Auth.Ad",
    AUTH_USER_PERMISSIONS: "Auth.UserPermissions"
});

export const TherapySessionLanguage = Object.freeze({
    PL: "pl",
    EN: "en",
    UA: "ua",
    RU: "ru",
    FR: "fr",
    IT: "it",
    DE: "de",
    ES: "es",
    CZ: "cz"
});
export const TherapySessionLanguageLabel = Object.freeze({
    [TherapySessionLanguage.PL]: "polski",
    [TherapySessionLanguage.EN]: "angielski",
    [TherapySessionLanguage.UA]: "ukraiński",
    [TherapySessionLanguage.RU]: "rosyjski",
    [TherapySessionLanguage.FR]: "francuski",
    [TherapySessionLanguage.IT]: "włoski",
    [TherapySessionLanguage.DE]: "niemiecki",
    [TherapySessionLanguage.ES]: "hiszpański",
    [TherapySessionLanguage.CZ]: "czeski"
});

export const TherapySessionLanguageSelectItems = Object.values(TherapySessionLanguage).map(it => ({
    text: TherapySessionLanguageLabel[it],
    value: it
}));

export const Currency = Object.freeze({
    PLN: "pln"
});

export const GTM_EVENT = Object.freeze({
    REFUND: "refund"
});

export const SW_CUSTOM_MESSAGES = Object.freeze({
    ONLINE_STATUS_CHANGE: "ONLINE_STATUS_CHANGE",
    AUTH_HEADER: "AUTH_HEADER"
});

export const Weekday = Object.freeze({
    MONDAY: "MONDAY",
    TUESDAY: "TUESDAY",
    WEDNESDAY: "WEDNESDAY",
    THURSDAY: "THURSDAY",
    FRIDAY: "FRIDAY",
    SATURDAY: "SATURDAY",
    SUNDAY: "SUNDAY"
});