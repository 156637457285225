<template>
    <div style="position: relative">
        <!-- 1. PASEK AKCYJNY -->
        <v-app-bar
            style="position: sticky; top: 0; z-index: 1"
            class="mb-1 rounded align-center"
            :color="selected_category ? 'primary' : ''"
            :light="selected_category === null"
            :outlined="!selected_category"
            :dark="selected_category !== null"
            dense
            flat
        >
            <template v-if="!selected_category">
                <v-btn
                    icon
                    small
                    v-if="parent_category"
                    :title="
                        'Wróć do ' +
                        (grand_parent_category ? grand_parent_category.name : ROOT_FOLDER_NAME)
                    "
                    class="ml-n1 mr-2"
                    @click="goBack"
                >
                    <v-icon size="20">mdi-arrow-left</v-icon>
                </v-btn>
                <div>
                    {{ breadcrumb_items.join(" / ") }}
                </div>
            </template>
            <template v-else>
                <v-btn
                    icon
                    @click="toggleCategorySelection(selected_category)"
                    small
                    class="ml-n1 mr-2"
                >
                    <v-icon size="20">mdi-close</v-icon>
                </v-btn>
                <div><span style="opacity: 0.6">Wybrano: </span>{{ selected_category.name }}</div>
            </template>

            <v-spacer></v-spacer>

            <template v-if="!selected_category">
                <v-btn
                    icon
                    small
                    v-if="!readonly"
                    title="Dodaj nową kategorię w tym miejscu"
                    @click="openAddDialog"
                >
                    <v-icon size="20">mdi-plus-circle</v-icon>
                </v-btn>
            </template>
            <template v-else>
                <template v-if="!readonly">
                    <v-btn
                        icon
                        small
                        @click="openEditDialog"
                        class="mr-1"
                        title="Edytuj wybraną kategorię"
                    >
                        <v-icon size="20">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        small
                        :loading="delete_loading"
                        @click="delete_dialog = true"
                        class="mr-1"
                        title="Usuń wybraną kategorię"
                    >
                        <v-icon size="20">mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        small
                        @click="
                            () => {
                                if (selected_category.parent_category) {
                                    move_to_category_id = selected_category.parent_category;
                                }
                                move_dialog = true;
                            }
                        "
                        title="Przenieś wybraną kategorię"
                    >
                        <v-icon size="20">mdi-folder-arrow-right</v-icon>
                    </v-btn>
                    <div
                        style="width: 1px; height: 100%"
                        class="grey lighten-2 mx-4"
                    ></div>
                </template>
                <div
                    class="pr-0"
                    v-if="selected_category"
                >
                    <v-btn
                        icon
                        small
                        @click="loadChildren(selected_category)"
                        :title="'Wejdź do kategorii ' + selected_category.name"
                    >
                        <v-icon size="20">mdi-folder-open</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-app-bar>

        <!-- 2. LISTA ELEMENTÓW -->
        <template v-if="categories.length > 0">
            <v-list-item
                v-for="(cat, cix) in categories"
                :key="cat._id"
                style="cursor: pointer; user-select: none"
                @click="onCategoryItemClick(cat)"
                :input-value="selected_category && cat._id === selected_category._id"
                color="primary"
                :style="
                    cix !== categories.length - 1
                        ? {
                              'border-bottom': '1px solid #ededed'
                          }
                        : {}
                "
            >
                <v-icon
                    v-if="selected_category && cat._id === selected_category._id"
                    size="20"
                    color="primary"
                    class="mr-2"
                    >mdi-radiobox-marked</v-icon
                >
                <v-icon
                    v-else
                    size="20"
                    color="grey"
                    class="mr-2"
                    >mdi-folder</v-icon
                >
                {{ cat.name }}
            </v-list-item>
            <div
                v-if="!categories_loading && has_more_current_category"
                v-intersect="onCategoryIntersect"
                style="height: 1px"
            ></div>
        </template>
        <div v-else-if="categories.length === 0 && !categories_loading">
            <div class="text-center grey--text text-caption py-2">
                Brak elementów do wyświetlenia
            </div>
        </div>
        <div
            v-if="categories_loading"
            class="text-center py-2"
        >
            <v-progress-circular
                indeterminate
                color="primary"
                size="24"
                width="2"
            ></v-progress-circular>
        </div>

        <!-- 3. CREATE DIALOG -->
        <v-dialog
            v-model="add_dialog"
            max-width="500px"
            scrollable
            persistent
        >
            <v-card>
                <v-card-title>
                    <div>
                        Dodaj kategorię
                        <div class="grey--text text-body-2">
                            w "{{ parent_category ? parent_category.name : ROOT_FOLDER_NAME }}"
                        </div>
                    </div>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text
                    class="pt-5"
                    style="max-height: 70vh"
                >
                    <v-text-field
                        outlined
                        label="Nazwa"
                        v-model="new_category.name"
                        :rules="[
                            v => {
                                if (!v) {
                                    return 'Pole wymagane';
                                }
                                if (v.length > 24) {
                                    return 'Nazwa nie może być dłuższa niż 24 znaki';
                                }
                                return true;
                            }
                        ]"
                        ref="new_category_name"
                        hide-details="auto"
                        class="mb-4"
                    ></v-text-field>
                    <v-textarea
                        outlined
                        label="Opis"
                        v-model="new_category.description"
                        :rules="[
                            v => {
                                if (!v) return true;
                                if (v.length > 255) {
                                    return 'Opis nie może być dłuższy niż 255 znaków';
                                }
                                return true;
                            }
                        ]"
                        ref="new_category_description"
                        hide-details="auto"
                        rows="3"
                        no-resize
                    ></v-textarea>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="grey"
                        text
                        @click="add_dialog = false"
                    >
                        Anuluj
                    </v-btn>
                    <v-btn
                        color="success"
                        text
                        @click="addCategory"
                    >
                        Dodaj
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- 4. EDIT DIALOG -->
        <v-dialog
            v-model="edit_dialog"
            max-width="500px"
            scrollable
            persistent
        >
            <v-card>
                <v-card-title>Edytuj kategorię</v-card-title>
                <v-divider></v-divider>
                <v-card-text
                    class="pt-5"
                    style="max-height: 70vh"
                >
                    <v-text-field
                        outlined
                        label="Nazwa"
                        v-model="edit_category.name"
                        :rules="[
                            v => {
                                if (!v) {
                                    return 'Pole wymagane';
                                }
                                if (v.length > 24) {
                                    return 'Nazwa nie może być dłuższa niż 24 znaki';
                                }
                                return true;
                            }
                        ]"
                        ref="edit_category_name"
                        hide-details="auto"
                        class="mb-4"
                    ></v-text-field>
                    <v-textarea
                        outlined
                        label="Opis"
                        v-model="edit_category.description"
                        :rules="[
                            v => {
                                if (!v) return true;
                                if (v.length > 255) {
                                    return 'Opis nie może być dłuższy niż 255 znaków';
                                }
                                return true;
                            }
                        ]"
                        ref="edit_category_description"
                        hide-details="auto"
                        rows="3"
                        no-resize
                    ></v-textarea>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="grey"
                        text
                        @click="edit_dialog = false"
                    >
                        Anuluj
                    </v-btn>
                    <v-btn
                        color="success"
                        text
                        @click="editCategory"
                    >
                        Zapisz
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- 5. DELETE DIALOG -->
        <v-dialog
            v-model="delete_dialog"
            max-width="500px"
        >
            <v-card>
                <v-card-title>Usuń kategorię</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-5">
                    Czy na pewno chcesz usunąć kategorię?
                    <span class="font-weight-bold">{{
                        selected_category ? selected_category.name : ""
                    }}</span>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="grey"
                        text
                        @click="delete_dialog = false"
                        >Anuluj</v-btn
                    >
                    <v-btn
                        color="error"
                        text
                        :loading="delete_loading"
                        @click="deleteCategory"
                        >Usuń</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- 6. MOVE DIALOG -->
        <v-dialog
            v-model="move_dialog"
            max-width="500px"
            persistent
            scrollable
        >
            <v-card>
                <v-card-title>
                    <div>
                        Przenieś kategorię
                        <div class="text-body-2 grey--text">
                            {{ selected_category ? selected_category.name : "n/a" }}
                        </div>
                    </div>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text
                    class="pt-5"
                    style="max-height: 70vh"
                >
                    <v-select
                        outlined
                        label="Kategoria nadrzędna elementu"
                        hide-details="auto"
                        readonly
                        :items="[
                            {
                                value: move_to_category_id,
                                text: '-'
                            }
                        ]"
                        :value="move_to_category_id"
                        :clearable="move_to_category_id !== null"
                        @click:clear="
                            () => {
                                move_to_category_id = null;
                            }
                        "
                        @click="openMoveDialogCategorySelector"
                        @click:append="openMoveDialogCategorySelector"
                    >
                        <template v-slot:selection>
                            <template v-if="move_to_category_id === null">
                                {{ ROOT_FOLDER_NAME }}
                            </template>
                            <DevProgramsAutoFetchEpisodeCategoryTile
                                v-else
                                :category-id="move_to_category_id"
                            />
                        </template>
                    </v-select>

                    <DevProgramsEpisodeCategorySelector
                        v-model="move_dialog_catsel"
                        @category-selected="
                            cat => {
                                move_to_category_id = cat._id;
                                move_dialog_catsel = false;
                            }
                        "
                        readonly
                        :initial-category-id="move_to_category_id"
                    />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="grey"
                        text
                        @click="move_dialog = false"
                    >
                        Anuluj
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        :loading="move_loading"
                        :disabled="!move_to_category_id && move_to_category_id !== null"
                        @click="moveCategory"
                    >
                        Przenieś
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DevProgramsEpisodeCategorySelector from "./DevProgramsEpisodeCategorySelector";
import DevProgramsAutoFetchEpisodeCategoryTile from "./DevProgramsAutoFetchEpisodeCategoryTile";
import { Stopwatch } from "../../helpers/waiters";

const ROOT_FOLDER_NAME = "Folder główny";

export default {
    name: "DevProgramsEpisodeCategoriesManager",

    props: {
        categoryId: {
            type: String,
            default: null
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },

    components: {
        DevProgramsAutoFetchEpisodeCategoryTile,
        DevProgramsEpisodeCategorySelector
    },

    data() {
        return {
            inited: false,

            parent_category: null,
            grand_parent_category: null,
            selected_category: null,

            category_item_click_time: 0,
            category_item_click_debouncer: null,

            categories: [],
            category_loading: false,
            categories_loading: false,
            has_more_current_category: true,
            current_category_cursor: null,

            add_dialog: false,
            add_loading: false,
            new_category: {
                name: "",
                description: ""
            },

            edit_dialog: false,
            edit_loading: false,
            edit_category: {
                name: "",
                description: ""
            },

            delete_dialog: false,
            delete_loading: false,

            move_dialog: false,
            move_loading: false,
            move_to_category_id: null,
            move_dialog_catsel: false,

            ROOT_FOLDER_NAME
        };
    },
    computed: {
        breadcrumb_items() {
            const items = [];
            if (this.parent_category) {
                items.push(this.parent_category.name);
                if (this.parent_category.parent_category && this.grand_parent_category) {
                    items.unshift(this.grand_parent_category.name);
                } else {
                    items.unshift(ROOT_FOLDER_NAME);
                }
            } else {
                items.push(ROOT_FOLDER_NAME);
            }
            return items;
        }
    },
    methods: {
        // 1. CATEGORY FETCHING & SELECTION
        async fetchCategories(parent_category = null, reset = false) {
            if (this.categories_loading) return;

            if (reset) {
                this.categories = [];
                this.current_category_cursor = null;
            }

            this.categories_loading = true;
            const SW = new Stopwatch();
            try {
                const response = await this.$axios.$get(
                    `/selfdev-program-episode-categories/?sort_by=name&filter_parent_category=${parent_category}&after=${this.current_category_cursor}`
                );

                await SW.waitUntil(300);

                this.categories = [
                    ...this.categories,
                    ...response.selfdev_program_episode_categories
                ];
                this.current_category_cursor = response.pagination.after;
                this.has_more_current_category = !!response.pagination.after;
            } catch (err) {
                console.error(err);
            } finally {
                this.categories_loading = false;
            }
        },
        async fetchCategory(category_id) {
            try {
                const response = await this.$axios.$get(
                    `/selfdev-program-episode-categories/${category_id}`
                );
                return response.selfdev_program_episode_category || null;
            } catch (err) {
                console.error(`Błąd podczas pobierania kategorii o ID: ${category_id}`, err);
                return null;
            }
        },

        async loadChildren(category) {
            if (this.selected_category) {
                this.selected_category = null;
                this.emitSelectedEvent(null);
            }

            this.parent_category = category;
            this.grand_parent_category = category.parent_category
                ? await this.fetchCategory(category.parent_category)
                : null;

            await this.fetchCategories(category._id, true);
        },
        async goBack() {
            if (this.parent_category && this.parent_category.parent_category) {
                this.parent_category = await this.fetchCategory(
                    this.parent_category.parent_category
                );
                this.grand_parent_category = this.parent_category.parent_category
                    ? await this.fetchCategory(this.parent_category.parent_category)
                    : null;
            } else {
                this.parent_category = null;
                this.grand_parent_category = null;
            }

            if (this.selected_category) {
                this.selected_category = null;
                this.emitSelectedEvent(null);
            }

            await this.fetchCategories(
                this.parent_category ? this.parent_category._id : null,
                true
            );
        },

        async onCategoryIntersect(entries, observer, isIntersecting) {
            if (this.categories_loading || !this.inited) return;

            if (isIntersecting && this.has_more_current_category) {
                await this.fetchCategories(this.parent_category ? this.parent_category._id : null);
            }
        },

        onCategoryItemClick(category) {
            const NOW = Date.now();
            if (NOW - this.category_item_click_time <= 300) {
                this.loadChildren(category);
                if (this.category_item_click_debouncer !== null) {
                    clearTimeout(this.category_item_click_debouncer);
                    this.category_item_click_debouncer = null;
                }
            } else {
                this.category_item_click_time = NOW;
                this.category_item_click_debouncer = setTimeout(() => {
                    this.toggleCategorySelection(category);
                    this.category_item_click_debouncer = null;
                }, 310);
            }
        },
        toggleCategorySelection(category) {
            if (this.selected_category && this.selected_category._id === category._id) {
                this.selected_category = null;
                this.emitSelectedEvent(null);
                return;
            }

            this.selected_category = category;
            this.emitSelectedEvent(category);
        },

        // 2. CREATING NEW CATEGORY
        openAddDialog() {
            this.new_category = {
                name: "",
                description: ""
            };
            this.add_dialog = true;
        },
        async addCategory() {
            if (this.add_loading) return;

            const a = [
                this.$refs.new_category_name.validate(),
                this.$refs.new_category_description.validate()
            ];
            if (a.indexOf(false) !== -1) return;

            this.add_loading = true;

            const parent_category = this.parent_category ? this.parent_category._id : null;
            const new_category = {
                name: this.new_category.name
            };
            if (this.new_category.description) {
                new_category.desc = this.new_category.description;
            }
            if (parent_category) {
                new_category.parent_category = parent_category;
            }

            try {
                const r = await this.$axios.$post(
                    `/selfdev-program-episode-categories/`,
                    new_category
                );

                this.categories.unshift(r.selfdev_program_episode_category);

                this.$message({
                    type: "success",
                    msg: "Kategoria została dodana pomyślnie"
                });

                this.add_dialog = false;
                this.new_category = {
                    name: "",
                    description: ""
                };
            } catch (err) {
                console.error(err);
            } finally {
                this.add_loading = false;
            }
        },

        // 3. EDITING CATEGORY
        openEditDialog() {
            if (this.selected_category) {
                this.edit_category = {
                    name: this.selected_category.name,
                    description: this.selected_category.desc
                };
                this.edit_dialog = true;
            }
        },
        async editCategory() {
            if (this.edit_loading) return;

            const a = [
                this.$refs.edit_category_name.validate(true),
                this.$refs.edit_category_description.validate(true)
            ];
            if (a.indexOf(false) !== -1) return;

            this.edit_loading = true;

            try {
                const UPD = {
                    name: this.edit_category.name
                };
                if (this.edit_category.description) {
                    UPD.desc = this.edit_category.description;
                } else {
                    UPD.desc = null;
                }

                const r = await this.$axios.$put(
                    `/selfdev-program-episode-categories/${this.selected_category._id}`,
                    UPD
                );

                const ix = this.categories.findIndex(
                    category => category._id === this.selected_category._id
                );
                if (ix !== -1) {
                    this.categories.splice(ix, 1, r.selfdev_program_episode_category);
                }
                this.selected_category = r.selfdev_program_episode_category;

                this.$message({
                    type: "success",
                    msg: "Kategoria została zaktualizowana pomyślnie"
                });

                this.edit_dialog = false;
            } catch (err) {
                console.error(err);
            } finally {
                this.edit_loading = false;
            }
        },

        // 4. DELETING CATEGORY
        async deleteCategory() {
            if (this.delete_loading) return;

            this.delete_loading = true;

            try {
                await this.$axios.$delete(
                    `/selfdev-program-episode-categories/${this.selected_category._id}`
                );

                const item_index = this.categories.findIndex(
                    c => c._id === this.selected_category._id
                );
                if (item_index !== -1) {
                    this.categories.splice(item_index, 1);
                }

                this.$message({
                    type: "success",
                    msg: "Kategoria została usunięta"
                });

                this.selected_category = null;
                this.emitSelectedEvent(null);

                this.delete_dialog = false;
            } catch (err) {
                console.error(err);
            } finally {
                this.delete_loading = false;
            }
        },

        // 5. MOVING CATEGORY
        openMoveDialogCategorySelector() {
            this.move_dialog_catsel = true;
        },
        async moveCategory() {
            if (this.move_loading) return;

            this.move_loading = true;

            try {
                const r = await this.$axios.$put(
                    `/selfdev-program-episode-categories/${this.selected_category._id}`,
                    {
                        parent_category: this.move_to_category_id
                    }
                );

                const item_index = this.categories.findIndex(
                    c => c._id === this.selected_category._id
                );

                if (item_index !== -1) {
                    this.categories.splice(item_index, 1);
                }

                this.$message({
                    type: "success",
                    msg: "Kategoria została przeniesiona"
                });

                this.selected_category = null;
                this.move_to_category_id = null;
                this.move_dialog = false;
            } catch (err) {
                console.error(err);
            } finally {
                this.move_loading = false;
            }
        },

        // 6. EMITS
        emitSelectedEvent(payload) {
            this.$emit("selection", payload);
        }
    },
    async mounted() {
        try {
            let current_category = null;
            let parent_category = null;

            if (this.categoryId) {
                current_category = await this.fetchCategory(this.categoryId);

                if (!current_category) {
                    console.warn("Nie znaleziono kategorii dla przekazanego ID:", this.categoryId);
                    await this.fetchCategories();
                    return;
                }

                parent_category = current_category.parent_category
                    ? await this.fetchCategory(current_category.parent_category)
                    : null;
            }

            this.parent_category = parent_category;
            if (current_category) {
                this.toggleCategorySelection(current_category);
            }

            await this.fetchCategories(parent_category?._id || null);
        } catch (err) {
            console.error("Błąd podczas inicjalizacji kategorii:", err);
        } finally {
            this.inited = true;
        }
    }
};
</script>
