export const SelfdevProgramContractParticipantEnrollmentStatus = Object.freeze({
    NONE: "none",
    PROGRAM_SELECTED: "program_selected",
    PROGRAM_STARTED: "program_started",
    PROGRAM_COMPLETED: "program_completed"
});

export const SelfdevProgramContractParticipantEnrollmentStatusLabel = Object.freeze({
    [SelfdevProgramContractParticipantEnrollmentStatus.NONE]: "Zaproszono",
    [SelfdevProgramContractParticipantEnrollmentStatus.PROGRAM_SELECTED]: "Program wybrany",
    [SelfdevProgramContractParticipantEnrollmentStatus.PROGRAM_STARTED]: "Program rozpoczęty",
    [SelfdevProgramContractParticipantEnrollmentStatus.PROGRAM_COMPLETED]: "Program zakończony",
});

export const SelfdevProgramContractParticipantEnrollmentStatusSelectItems = Object.values(SelfdevProgramContractParticipantEnrollmentStatus).map(it => {
    return {
        text: SelfdevProgramContractParticipantEnrollmentStatusLabel[it],
        value: it
    };
});